.navLayout {
    display: flex;
    justify-content: center;
}
.navbar {
    align-self: center;
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    font-size: 10px;
    width: 90vw;
    justify-content: center;
    position: relative;
    z-index: 1000;
}
.navLink {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    font-size: 10px;
    width: 100vw;
    justify-content: center;
    z-index: 1000;
}
.navLinks {
    color: white;
    text-transform: uppercase;
    opacity: 50%;
    transition: ease-in-out;
    transition-duration: .2s;
}
.navLinks:hover {
    opacity: 100%;
    cursor: pointer;
}

.navContainer {
    margin-top: 10px;
    margin-bottom: 30px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    z-index: 1000;
}

.navItems {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    font-size: 10px;
    align-content: center;
}

.topLogo {
    opacity: 50%;
    width: 13vh;
    height: auto;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../Images/AngryJoeLogo.png');
    align-content: flex-start;
}

.nav-btn {
    padding: 5px;
    cursor: pointer;
    background:transparent;
    border: none;
    outline: none;
    color: white;
    opacity: 0;
    visibility: hidden;
    font-size: 1.8rem
}
.navMobileItems {
    display: flex;
    justify-content: center;
    width: 13vh;
    height: 100%;
    visibility: hidden;
}

@media only screen and (max-width: 1024px) {
    .navMobileItems {
        width: 13vh;
        height: 100%;
        visibility: visible;
    }
    .nav-btn {
        visibility: visible;
        opacity: 1;
    }

    .navbar {
        align-self: center;
       justify-content: space-between;
       width: 100vw;


    }

    nav {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: black;
		transition: 1s;
		transform: translateY(-101vh);
        z-index: 1000;
    }

    .responsive_nav {
        transform: none;
    }

    nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
    }
    .navLink {
        flex-direction: column;
    }


}