.heroContainer {
    width: 100vw;
    height: 100vh;
    background-image: url('../../Images/heroBackground.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow-x: hidden;

}

.heroItems {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    text-align: center;
    filter: drop-shadow(-5px -7px 12px #000);
}

.mainTitle {
    text-transform: uppercase;
    font-size: 45px;
    font-weight: bold;
    margin: 0;
}

.subTitle {
    opacity: 60%;
    margin: 0;
}

.heroLogo {
    width: 25%;
    transform: translate(0, 10px);
}

.heroButton {
    width: 110px;
    height: 35px;
    background-color: white;
    color: black;
    border-radius: 50px;
    margin: 10px;
    border: 0;
    font-weight: bold;
    font-size: 14px;
    transition: 0.5s;
    cursor: pointer;

}

.heroButton:hover {
    cursor: pointer;
    opacity: 60%;
}

.spacer {
    height: 220px;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
    margin: 0;
}
.spacer2 {
    height: 110px;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.heroWave {
background-image: url('../../Images/mainWave.svg');
background: rgb(35,35,34);
background: linear-gradient(0deg, rgba(35,35,34,1) 10%, rgba(255,255,255,0) 100%);
}

.background2 {
    background-image: url('../../Images/svgBg.svg');
    transform: rotate(180deg);
}

.background3 {
    background-image: url('../../Images/svgBg.svg');
}

.heroSpacerTitle {
    font-weight: bold;
    margin: 0;
    margin-top: 10vh;
    margin-bottom: 5px;
}

.heroArrow {
    font-size: 20px;

}

.aboutUsContainer{
    height: 80vh;
    width: 100%;
    background-color: #232322;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.aboutUsItem {
    width: 65%;
    font-size: 1rem;
    opacity: 65%;
}

.menuContainer {
    height: auto;
    padding-top: 0;
    width: 100vw;
    background-color: #F8F9FA;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: bold;
    align-items: center;
    flex-wrap: wrap;

}
.menuItems {
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;
    row-gap: 20px;
    margin-bottom: 150px;
    justify-content: center;

}

.menuItem {

    border-radius: 12px;
    height: 70px;
    width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    font-size: 20px;
    box-shadow: 0px 0px 13px 2px rgba(0,0,0,0.18);
    flex-wrap: wrap;
    animation: menuAnimation 1.2s ease-in-out forwards;
}
@keyframes menuAnimation {
    0% {
        transform: translate(0, -30px);
        opacity: 50%;
    }
    100% {
        transform: translate(0, 0);
        opacity: 100%;
    }
}
.itemIcon {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: black;
}

.menuTitle {
    margin: 0;
    margin-bottom: 25px;
    font-size: 25px;
    width: 250px;
}

.upcomingEventsContainer {
    background-color: #232322;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.eventCard {
    margin-top: 20px;
    display: flex;
    padding: 10px;
    border-radius: 18px;
    justify-content: center;
    width: 50vw;
    height: 7vh;
    background-color: white;
    align-items: center;
    text-align: center;
    box-shadow: -1px 0px 30px 0px rgba(0, 0, 0, 0.97);
}

.cardMiddle {
    text-align: center;
    width: 20vw
}
.cardLeft {
    width: 12vw;
}
.date {
    font-weight: bold;
}

.time {
    opacity: 50%;
    font-size: 12px;
}

.cardTitle {
    font-weight: bold;
}
.cardLocation {
    opacity: 50%;
    font-size: 12px;
}

.eventButton {
    height: 6vh;
    width: 10vw;
    font-weight: bold;
    border-radius: 15px;
    border: 0;
    background-color: #232322;
    color: white;
    cursor: pointer;
    transition: 0.5s;
}

.eventButton:hover {
    opacity: 75%;
}

.eventsTitle {
    color:white;
    font-size: 20px;
}

.contactContainer {
    width: 100vw;
    height: 80vh;
    background-color: #F8F9FA;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contactItems {
    width: 80vw;
    display: flex;
    column-gap: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.contactItem {
    width: 300px;
}
.contactCircle {
    width: 280px;
    height: 280px;
    border-radius: 50%;
    background-color: #232322
}
.socialBox {
    display: flex;
    flex-direction: row;
}
.socialIcon {
    font-size: 35px;
    color: black;
}

.contactTitle {
    font-size: 25px;
}

.menuBot {
    margin-top: 10px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menuTitle {
    margin: 0;
    font-size: 16px;
}

.menuPrice {
    margin: 0;
    opacity: 65%;
    font-size: 15px;
}

a {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:link {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

.noItem {
    display: none;
    visibility: hidden;
}

@media only screen and (max-width: 1024px) {
    .contactCircle {
        display: none;
    }
    .eventCard {
        width: 90vw;
    }
    .cardLeft {
        width: 20vw
    }
    .cardMiddle {
        width: 40vw;
    }
    .eventButton {
        width: 22vw;
        font-size: 12px;
    }
    .menuItem {
        padding-left: 10px;
        width: 290px;
        font-size: 12px;
    }
    .menuTitle {
        font-size: 14px;
        text-align: left;
        width: 240px;
    }
    .aboutUsContainer {
        height: 100vh;
    }
}

.icon1 {
    background-image: url('../../Images/sausage.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.icon2 {
    background-image: url('../../Images/pulledpork.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 300px
}
.icon3 {
    background-image: url('../../Images/coleslaw.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.icon4 {
    background-image: url('../../Images/beans.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.foodIcon {
    width: 150px;
    height: 150px;
}
.foodIcon2 {
    width: 230px;
    height: 230px;
    transform: translate(-52px, -42px);
}
.foodIcon3 {
    width: 150px;
    height: 150px;
    transform: translate(-13px, 2px);
}
.foodIcon4 {
    width: 175px;
    height: 175px;
    transform: translate(-26px, -28px);
}

.otherNav {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: right;

    background-color: black;
}

.backButton {
    margin-right: 20px;
}

.backButton:a {
    color: white;
}

a {
    color: white;
}