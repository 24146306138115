.footerContainer {
    background-color: #212121;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:40px;
    padding-bottom:40px;
    overflow-x: hidden;
}

.footerItems {
    display: flex;
    flex-direction: row;
    column-gap: 50px;
    margin: 0;
    margin-left: 10px;
    text-align: left;
    flex-wrap: wrap;
}

.footerItem {
    row-gap: 0px;
    column-gap: 0;
    color: #818181;
    margin: 0;
    text-align: left;
}

.footerText {
    margin: 5px;
    color: #818181;
    
}

.footerTitle {
    margin: 5px;
    color: white;
    font-size: 25px;
    
}

.footerLogo {
    width: 12vw;
}

.pAirplane {
    font-size: 200px;
    color: white;
    transform: translate(15px, 40px);
}

@media only screen and (max-width: 1024px) {
    .footerLogo {
        display: none;
    }
    .footerItem {
        width: 50vw;
    }
}